import React from "react";
import Lottie from "react-lottie";
import mobilesvg from "../../../assets/experience.json";

const Experience = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: mobilesvg,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="pt-24">
      <div className="mb-12">
        <h1 className="text-4xl font-semibold drop-shadow-md text-center">
          My <span className="text-primary">Experience</span>
        </h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8">
        <div className="">
          <Lottie
            options={defaultOptions}
            height="70%"
            width="90%"
            className="mx-auto lg:mr-auto"
          />
        </div>
        <div className="mx-auto lg:ml-auto">
          {/* Engineering */}
          <div className="p-6 bg-[#313131] bg-opacity-90 backdrop-blur-lg rounded-lg shadow-lg inline-block w-[300px] hover:shadow-primary duration-300 cursor-pointer">
            <div className="text-right mb-4">
              <h3 className="text-xl font-semibold text-primary">
                One Immersive
              </h3>
              <p className="text-sm text-neutral font-semibold">
                April 2024 - Present
              </p>
              <p className="text-sm text-neutral font-medium">SDE-1</p>
            </div>
            <p className="text-sm text-neutral text-justify">
              Worked on AR 3d Editor.
            </p>
          </div>

          {/* {Inter} */}
          <div className="my-6 md:my-4 md:ml-[200px] p-6 bg-[#313131] bg-opacity-90 backdrop-blur-lg rounded-lg shadow-lg w-[300px] hover:shadow-primary duration-300 cursor-pointer">
            <div className="text-right mb-4">
              <h3 className="text-2xl font-semibold text-primary">Zakrotix</h3>

              <p className="text-sm text-neutral font-semibold">
                2023 May - 2023 July
              </p>
              <p className="text-sm text-neutral font-medium">Web Developer</p>
            </div>
            <p className="text-sm text-neutral text-justify">
              Worked on E-commerce Site (React Js, Node Js)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
