const Items = [
  {
    id: 1,
    mainImage:
      "https://ik.imagekit.io/8mbmyzlup/Projects/100dayscoding/1.png?updatedAt=1732631070042",
    img: [
      "https://ik.imagekit.io/8mbmyzlup/Projects/100dayscoding/1.png?updatedAt=1732631070042",
      "https://ik.imagekit.io/8mbmyzlup/Projects/100dayscoding/3.png?updatedAt=1732631069208",
      "https://ik.imagekit.io/8mbmyzlup/Projects/100dayscoding/4.png?updatedAt=1732631069205",
      "https://ik.imagekit.io/8mbmyzlup/Projects/100dayscoding/2.png?updatedAt=1732631069110",
    ],
    features: [
      "Home",
      "Code Run Page",
      "Profile Page",
      "Responsive Design",
      "404 Page and many more.",
    ],
    technologies: [
      "React",
      "TailwindCSS",
      "React Toast",
      "React Hooks",
      "React icons",
      "Github API",
      "Compiler API",
    ],
    title: "100daysofcoding",
    description:
      "Developed an application to enhance coding skills by providing daily DSA problems for 100 days, supporting multiple languages such as C, C++, Java, Python, and JavaScript. The app includes an integrated compiler and allows users to push their solved code to a GitHub repository with a single click, with the repository automatically created during account registration. Built using React.js, Tailwind CSS, Node.js, and Express, the application leverages the GitHub API for seamless authentication and repository management.",
    category: "Education",
    liveLink: "https://100daysofcoding.com/",
    codeLink: "https://github.com/fawaz-exe/100daysofcoding-Challenge-App",
  },
  {
    id: 2,
    mainImage:
      "https://ik.imagekit.io/8mbmyzlup/Projects/arena/1.png?updatedAt=1732632063784",
    img: [
      "https://ik.imagekit.io/8mbmyzlup/Projects/arena/1.png?updatedAt=1732632063784",
      "https://ik.imagekit.io/8mbmyzlup/Projects/arena/2.png?updatedAt=1732632059473",
      "https://ik.imagekit.io/8mbmyzlup/Projects/arena/3.png?updatedAt=1732632063621",
      "https://ik.imagekit.io/8mbmyzlup/Projects/arena/4.png?updatedAt=1732632063655",
      "https://ik.imagekit.io/8mbmyzlup/Projects/arena/5.png?updatedAt=1732632063483",
      "https://ik.imagekit.io/8mbmyzlup/Projects/arena/6.png?updatedAt=1732632062471",
      "https://ik.imagekit.io/8mbmyzlup/Projects/arena/7.png?updatedAt=1732632062817",
    ],
    features: [
      "User registration and login",
      "Authentication using JWT Tokens",
      "Place Ads",
      "CRUD operations (create, read, update and delete)",
      "Manage Assets (CRUD)",
      "Adding Team Members",
      "Manage Subscription Page",
      "Help Page",
      "Site Tour",
      "Responsive Design",
      "404 Page and many more.",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Icons",
      "Axios",
      "CSS",
      "Firebase",
      "Google Maps",
      "AWS",
      "ImageKit",
      "Shadcn",
      "NodeJS",
      "ExpressJS",
      "MongoDB",
      "Mongoose",
      "Express Asynsc Handler",
      "Json Web Token",
      "Bcryptjs",
      "Nodemailer",
      "Dontenv",
      "Multer",
      "Material UI",
      "Cors",
    ],
    title: "Arena Explore",
    description: "",
    category: "business",
    liveLink: "https://arenaexplore.com/",
    codeLink: "",
  },
  {
    id: 3,
    mainImage:
      "https://ik.imagekit.io/8mbmyzlup/Projects/OneAr/1.png?updatedAt=1732632321873",
    img: [
      "https://ik.imagekit.io/8mbmyzlup/Projects/OneAr/1.png?updatedAt=1732632321873",
      "https://ik.imagekit.io/8mbmyzlup/Projects/OneAr/2.png?updatedAt=1732632322999",
      "https://ik.imagekit.io/8mbmyzlup/Projects/OneAr/3.png?updatedAt=1732632323070",
      "https://ik.imagekit.io/8mbmyzlup/Projects/OneAr/4.png?updatedAt=1732632321540",
      "https://ik.imagekit.io/8mbmyzlup/Projects/OneAr/5.png?updatedAt=1732632319179",
    ],
    features: [
      "Login Admin",
      "Upload 3D models in various formats for customization.",
      "Preset Geometeries",
      "Customization Tools",
      "Sharing Options",
      "AR View",
      "Interactive 3D Environment",
      "Cross-Platform Integration",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Icons",
      "React Vite",
      "React Context API",
      "React Three Fiber",
      "Tailwind CSS",
      "Three Js",
      "React Drei",
      "Axios",
      "Node JS",
      "Express JS",
      "MySQL",
      "Cors",
      "JWT",
      "Bycrypt",
      "Dotenv",
      "MySQL 12",
      "Nodemon",
    ],
    title: "3D Editor",
    description:
      "The 3D Editor Web App lets users upload and customize 3D models with powerful tools.",
    category: "business",
    liveLink: "",
    codeLink: "",
  },
  {
    id: 4,
    mainImage:
      "https://ik.imagekit.io/8mbmyzlup/Projects/project%20management/dashboard.png?updatedAt=1732631216144",
    img: [
      "https://ik.imagekit.io/8mbmyzlup/Projects/project%20management/dashboard.png?updatedAt=1732631216144",
      "https://ik.imagekit.io/8mbmyzlup/Projects/project%20management/login%20.png?updatedAt=1732631216070",
      "https://ik.imagekit.io/8mbmyzlup/Projects/project%20management/user%20page.png?updatedAt=1732631216121",
      "https://ik.imagekit.io/8mbmyzlup/Projects/project%20management/profile.png?updatedAt=1732631216139",
    ],
    features: [
      "Dashboard",
      "Add Project",
      "Edit Project",
      "Remove Project",
      "No Project Page",
      "Profile Page",
      "Status",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Router Dom",
      "Axios",
      "Bootstrap",
      "React Icons",
      "Sweet Alert2",
      "Node JS",
      "Express JS",
      "Cors",
      "Mongoose",
      "Dotenv",
      "Nodemon",
      "MongoDB",
    ],
    title: "Project Management ",
    description:
      "Fullstack open source Project management system application made with MongoDB, Express, React & Nodejs (MERN)",
    category: "personal",
    liveLink: "",
    codeLink: "https://github.com/Hanzalashaik/project-management-mern",
  },
  {
    id: 5,
    mainImage:
      "https://ik.imagekit.io/8mbmyzlup/Projects/chatapp/Homepage.png?updatedAt=1732631947049",
    img: [
      "https://ik.imagekit.io/8mbmyzlup/Projects/chatapp/Homepage.png?updatedAt=1732631947049",
      "https://ik.imagekit.io/8mbmyzlup/Projects/chatapp/login%20page.png?updatedAt=1732631947122",
      "https://ik.imagekit.io/8mbmyzlup/Projects/chatapp/signup%20page.png?updatedAt=1732631947079",
    ],
    features: [
      "User registration and login",
      "Authentication using JWT Tokens",
      "Chat Page",
      "Search User",
      "Active Status",
      "Skeleton loading effect",
      "Responsive Design",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Router Dom",
      "WebSockets",
      "Axios",
      "Material UI",
      "React Toastify",
      "NodeJS",
      "ExpressJS",
      "Mongoose",
      "Json Web Token",
      "Cookie Parser",
      "Cors",
      "Dontenv",
      "Express Validator",
      "Nodemon",
      "MongoDB",
    ],
    title: "ChatApp",
    description:
      "This Real-Time Chat Web Application offers a seamless platform for users to engage in live conversations. Leveraging the power of WebSockets for real-time communication and JWT for secure user authentication, this application provides a dynamic and secure environment for chatting.",
    category: "personal",
    liveLink: "",
    codeLink: "https://github.com/Hanzalashaik/Real-Time-Chat-App",
  },
  {
    id: 6,
    mainImage:
      "https://ik.imagekit.io/8mbmyzlup/Projects/countdown%20game/home.png?updatedAt=1732631974797",
    img: [
      "https://ik.imagekit.io/8mbmyzlup/Projects/countdown%20game/home.png?updatedAt=1732631974797",
      "https://ik.imagekit.io/8mbmyzlup/Projects/countdown%20game/score.png?updatedAt=1732631974311",
    ],
    features: ["Mobile Responsive", "Beautiful Animations", "High Score"],
    technologies: [
      "React",
      "Tailwind CSS",
      "React Hooks",
      "React Router",
      "Context API",
    ],
    title: "CountDown Game",
    description:
      "The Almost Final Countdown is an interactive game built using React.js that challenges players to stop the timer once they estimate that time is (almost) up. The game is designed to test players' reflexes and precision, providing an engaging experience using React's ref system and portals for efficient rendering.",
    category: "game",
    liveLink: "",
    codeLink: "https://github.com/Hanzalashaik/countDown-game",
  },
  {
    id: 7,
    mainImage:
      "https://ik.imagekit.io/8mbmyzlup/Projects/quiz%20game/quiz%20screen.png?updatedAt=1732631920997",
    img: [
      "https://ik.imagekit.io/8mbmyzlup/Projects/quiz%20game/quiz%20screen.png?updatedAt=1732631920997",
      "https://ik.imagekit.io/8mbmyzlup/Projects/quiz%20game/quiz%20result.png?updatedAt=1732631920977",
    ],
    features: [
      "Select the type of question and the difficulty level of the question",
      "Juis time is running and after the time is up it stops automatically",
      "The final results of the answers answered will be shown",
      "Incorrect and correct answer results will be displayed",
      "Responsive Design",
    ],
    technologies: ["React", "React Vite", "Tailwind CSS", "React Hooks"],
    title: "Bert Quiz",
    description: "Fullstack open source quiz application",
    category: "game",
    liveLink: "",
    codeLink: "https://github.com/Hanzalashaik/quiz-app",
  },
];

export default Items;
